import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, throwError } from 'rxjs';
import { Environment } from '../../environments/environment';
import { IRestResponse } from '../core/interface/response.interface';
import { ICompany } from '../interfaces/company.interface';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class selectedTenantService {
  private selectedTenant = new BehaviorSubject<string>('MAIN')

  public selectedTenantValue = this.selectedTenant.asObservable()

  setSelectedTenant(value: string) {
    this.selectedTenant.next(value)
  }

  getSelectedTenant() {
    return this.selectedTenant.getValue()
  }

  constructor(private restService: RestService) { }

  public getSubCompanies(tenantId: string): Observable<IRestResponse<ICompany[]>> {
    return this.restService
      .get(Environment.UrlPath + `/company/tenants/${tenantId}`, null, false)
      .pipe(
        map((res: any) => res),
        catchError((error) => throwError(error))
      )
  }

}
